import(/* webpackMode: "eager" */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/admin/prodmob-webapp/mob-webapp/src/components/layout/Body/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrencyProvider"] */ "/home/admin/prodmob-webapp/mob-webapp/src/contexts/CurrencyContenxt/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/admin/prodmob-webapp/mob-webapp/src/contexts/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/admin/prodmob-webapp/mob-webapp/src/styles/global.scss");
