'use client';

import { usePathname } from 'next/navigation';
import classNames from 'classnames';

import { outfit } from '@/styles/fonts';
import { pagesWithBodyBgGray, checkIfHas } from '@/utils/layout';

const Body = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname();
  const isBgGray = checkIfHas(pagesWithBodyBgGray, pathname);
  const bodyClasses = classNames([outfit.className, { 'bg-gray': isBgGray }]);

  return (
    <body id="body" className={bodyClasses}>
      {children}
    </body>
  );
};

export default Body;
